document.addEventListener("DOMContentLoaded", function () {
  $(window).on("load resize", function () {
    if (
      this.matchMedia("(min-width: 1200px)").matches &&
      $("body").css("overflow") == "hidden"
    ) {
      $("body").css("overflow", "auto");
    } else {
      if ($(".mobile-toggle-cta").attr("aria-expanded") === "true") {
        $("body").css("overflow", "hidden");
      }
    }
  });

  // suppress page scroll when mobile nav opened
  $(".mobile-toggle-cta").click(function (e) {
    var menuItem = $(this);

    if (menuItem.attr("aria-expanded") === "true") {
      $("body").css("overflow", "hidden");
    } else if (menuItem.attr("aria-expanded") === "false") {
      $("body").css("overflow", "auto");
    }
  });

  $("#aux_nav_toggle").click(function (e) {
    $(this).toggleClass("open");
    $("#aux_nav").toggleClass("hide");
  });
});
