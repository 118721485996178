(function ($) {
  "use strict";

  $(function () {
    // mobile filters
    $(".-filters .header").on("click", function () {
      console.log("triggered");
      $(this).toggleClass("show");
      $(this).siblings(".wrapper").toggleClass("show");
    });
    // Comments
    $(".commentlist li").addClass("card mb-3");
    $(".comment-reply-link").addClass("btn btn-secondary");

    // Forms
    $(
      "select, input[type=text], input[type=email], input[type=password], textarea"
    ).addClass("form-control");
    $("input[type=submit]").addClass("btn btn-primary");

    // Pagination fix for ellipsis
    $(".pagination .dots").addClass("page-link").parent().addClass("disabled");

    //Play cta: hide poster display video onclick
    $(".play").on("click", function () {
      $(this).parent().siblings(".video").removeClass("hide");
      $(this).closest(".poster").addClass("hide");
    });

    //Readmore cta
    $("#readmore").on("click", function () {
      //$(this).addClass('hide');
      if ($(this).html() === "Read more") {
        $(this).html("Read less");
        $("#showmore").removeClass("hide");
      } else {
        $(this).html("Read more");
        $("#showmore").addClass("hide");
      }
    });

    // copy to clipboard
    $(".to-clipboard").on("click", function () {
      var $temp = $("<input>");
      var $url = $(location).attr("href");
      $("body").append($temp);
      $temp.val($url).select();
      document.execCommand("copy");
      $temp.remove();
      $("#copied_url").removeClass("vis-hidden");
    });
    $(window).resize(function () {
      var numitems = $("#press-materials li").length;
      if ($(window).width() < 1400) {
        $("ul#press-materials").css("column-count", 2);
      } else {
        $("ul#press-materials").css("column-count", Math.round(numitems / 2));
      }
    });
  });

  $("img").on("error", function () {
    $(this).attr("src", "https://placehold.co/600?text=Leaders In Logistics");
  });
})(jQuery);
function copyUrl() {
  if (!window.getSelection) {
    alert("Please copy the URL from the location bar.");
    return;
  }
  const dummy = document.createElement("p");
  dummy.textContent = window.location.href;
  document.body.appendChild(dummy);

  const range = document.createRange();
  range.setStartBefore(dummy);
  range.setEndAfter(dummy);

  const selection = window.getSelection();
  // First clear, in case the user already selected some other text
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand("copy");
  document.body.removeChild(dummy);
}

/**
 * Remove hash and params of URLs
 */
function getCleanUrl(url) {
  return url.replace(/#.*$/, "").replace(/\?.*$/, "");
}
