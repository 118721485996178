//THEME
import defaults from "./custom/defaults.js";
import navbar from "./custom/navbar.js";
import footer from "./custom/footer.js";
import parallax from "./custom/parallax.js";
//import anchorNav from "./custom/anchorNav.js";
import avatar from "./custom/avatar.js";
import megaMenu from "./custom/megaMenu.js";
import slickFunctions from "./custom/slickFunctions.js";
import agenda from "./custom/agenda.js";
import agenda_mobile from "./custom/agenda_mobile.js";
import scrollbar from "./custom/scrollbar.js";
//import apiGetEvents from "./custom/apiGetEvents.js";

//EXTRAS
import cards from "./custom/cards.js";
import animate from "./custom/animate.js";
//import vhb from "./custom/vhb.js";
//import ajaxWoo from "./custom/ajax-woo.js";
//import progressBar from "./custom/progressBar.js";
//VueJS
//import footer from "./custom/vue-scripts.js";
