(function ($) {
  "use strict";

  $(function () {
    $("body").on("click", function (e) {
      if ($(e.target).closest(".menupanel-sectors").length == 0) {
        $("#menupanel-sectors").css("display", "none");
      }
    });
    $(".menupanel-sectors").on({
      click: function () {
        if (window.innerWidth > 1199) {
          if ($("#menupanel-sectors").css("display") == "none") {
            $("#menupanel-sectors").css("display", "block");
            $(".menupanel-sectors .dropdown-toggle").addClass("rotate");
          } else {
            $("#menupanel-sectors").css("display", "none");
            $(".menupanel-sectors .dropdown-toggle").removeClass("rotate");
          }
        }
      },
    });
    $(window).on("load resize", function () {
      if (this.matchMedia("(max-width: 996px)").matches) {
        $("#menupanel-sectors").css("display", "none");
        $(".menupanel-sectors .dropdown-toggle").removeClass("rotate");
      }
    });
    $("#search_all").on({
      click: function () {
        if ($("#menupanel-search").css("display") == "none") {
          $("#menupanel-search").css("display", "block");
          $(".mobile-toggle-cta").addClass("collapsed");
          $("#main_nav").removeClass("show");
          document.body.style.position = "fixed";
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.position = "";
          document.body.style.top = "";
        } else {
          $("#menupanel-search").css("display", "none");

          const scrollY = document.body.style.top;
          document.body.style.position = "";
          document.body.style.top = "";
          window.scrollTo(0, parseInt(scrollY || "0") * -1);
        }
      },
    });
    $("#navbar_searchform").on({
      submit: function () {
        if ($("#menupanel-search").css("display") == "none") {
          $("#menupanel-search").css("display", "block");

          document.body.style.position = "fixed";
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.position = "";
          document.body.style.top = "";
        } else {
          $("#menupanel-search").css("display", "none");

          const scrollY = document.body.style.top;
          document.body.style.position = "";
          document.body.style.top = "";
          window.scrollTo(0, parseInt(scrollY || "0") * -1);
        }

        //}
      },
    });
    $("#closesearch").on({
      click: function () {
        $("#menupanel-search").css("display", "none");
      },
    });
  });
})(jQuery);
