if ($(".el-event-agenda-content").length > 0) {
  console.log("scrollbar.js");

  // Attend que le document soit prêt
  $(document).ready(function () {
    // Utilise la boucle each pour appliquer SimpleBar à chaque élément avec la classe .el-event-agnda-content
    $(".el-event-agenda-content").each(function () {
      new SimpleBar(this);
    });
  });
}
