(function ($) {
  $(function () {
    sr = ScrollReveal();
    sr.debug = true;
    //Generals
    // sr.reveal("section", {
    //   duration: 3000,
    //   opacity: 0.2,
    // });
    // sr.reveal("h1", {
    //   duration: 1500,
    //   origin: "top",
    //   distance: "20px",
    //   viewFactor: 0.2,
    // });

    // sr.reveal(".container", {
    //   duration: 1500,
    //   opacity: 0,
    // });

    // sr.reveal("h2", {
    //   duration: 3000,
    //   opacity: 0,
    // });
    // sr.reveal("h3", {
    //   duration: 3000,
    //   opacity: 0,
    // });
    // sr.reveal("h4", {
    //   duration: 3000,
    //   opacity: 0,
    // });
    // sr.reveal("h5", {
    //   duration: 3000,
    //   opacity: 0,
    // });
    // sr.reveal(".-partners img", {
    //   duration: 1500,
    //   origin: "top",
    //   distance: "30px",
    //   viewFactor: 0.3,
    // });

    // sr.reveal("", {
    //   duration: 1500,
    //   origin: "bottom",
    //   distance: "30px",
    //   viewFactor: 0.3,
    // });
    var flag = true;
    $(window).bind("load scroll", function () {
      if ($(".stats").length && $(".stats").isInViewport()) {
        if (flag) {
          $(".stat-slide").each(function (i) {
            var value = $(this).find(".-value").data("number");
            var symbol = $(this).find(".-value").data("symbol");
            $(this)
              .find(".-value")
              .attr("id", "value-" + i);
            animateValue("value-" + i, 0, value, symbol, 1000);
          });
          flag = false;
        }
      } else {
        // console.log("not in view");
      }
    });
  });
  $.fn.isInViewport = function () {
    // if ($(this).length) {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
    // }
  };
})(jQuery);

function animateValue(id, start, end, symbol, duration) {
  var obj = document.getElementById(id);
  var range = end - start;
  var minTimer = 50;
  var stepTime = Math.abs(Math.floor(duration / range));

  stepTime = Math.max(stepTime, minTimer);

  var startTime = new Date().getTime();
  var endTime = startTime + duration;
  var timer;

  function run() {
    var now = new Date().getTime();
    var remaining = Math.max((endTime - now) / duration, 0);
    var value = Math.round(end - remaining * range);
    obj.innerHTML = value + "" + symbol;
    if (value == end) {
      clearInterval(timer);
    }
  }

  timer = setInterval(run, stepTime);
  run();
}
window.animateValue = animateValue;

// function isInViewport(el) {
//   const rect = el.getBoundingClientRect();
//   return (
//     rect.top >= 0 &&
//     rect.left >= 0 &&
//     rect.bottom <=
//       (window.innerHeight || document.documentElement.clientHeight) &&
//     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//   );
// }
