function setHeight(agendaDays) {
  agendaDays.forEach((day) => {
    let asideElement = day.querySelector(".el-event-agenda-main-nav-list");
    let container = day.querySelector(".el-event-agenda-content");

    // let asideHeight = asideElement.clientHeight + 20 -> DEPRECATED;
    let asideHeight = asideElement.clientHeight;

    if (asideHeight > 500) {
      day.style.minHeight = `${asideHeight}px`;
      container.style.minHeight = `${asideHeight}px`;
    }

    day.style.maxHeight = `${asideHeight}px`;
    container.style.maxHeight = `${asideHeight}px`;
  });
}

function init(selectedDay, agendaDays) {
  // Hide all DAY divs
  agendaDays.forEach(function (agendaDay) {
    agendaDay.classList.add("-hidden");
  });

  // show targeted DAY div
  let selectedAgendaDay = document.querySelector(
    '.el-event-agenda-day[data-day="' + selectedDay + '"]'
  );
  if (selectedAgendaDay) {
    /**
     * SESSION NAV
     */
    //Remove .-active on all session buttons
    selectedAgendaDay
      .querySelectorAll(".session")
      .forEach(function (sessionBtn) {
        sessionBtn.classList.remove("-active");
      });

    // Add .-active on first session button of the list
    selectedAgendaDay.querySelectorAll(".session")[0].classList.add("-active");

    /**
     * STAGE NAV
     */
    //Hide all stage nav in the page
    selectedAgendaDay
      .querySelectorAll(".el-event-agenda-session-nav")
      .forEach(function (sessionBtn) {
        sessionBtn.classList.add("-hidden");
      });

    //Show first stage nav (aka linked to first session button)
    selectedAgendaDay
      .querySelectorAll(".el-event-agenda-session-nav")[0]
      .classList.remove("-hidden");

    // Remove active class to all current day stage nav buttons
    selectedAgendaDay
      .querySelectorAll(".sub-session")
      .forEach(function (sessionBtn) {
        sessionBtn.classList.remove("-active");
      });

    //Add active state to the first button of the first stage nav
    selectedAgendaDay
      .querySelectorAll(".sub-session")[0]
      .classList.add("-active");

    /**
     * CONTENT
     */
    //Show parent container of selected day
    selectedAgendaDay.classList.remove("-hidden");

    // ARTICLES
    // Hide all articles (includes the stages)
    selectedAgendaDay
      .querySelectorAll(".el-event-agenda-session")
      .forEach(function (sessionDiv) {
        sessionDiv.classList.add("-hidden");
      });

    // Show first session article in the list
    selectedAgendaDay
      .querySelectorAll(".el-event-agenda-session")[0]
      .classList.remove("-hidden");
  }

  // Show all stage div of the first session article
  const agendaSessions0 = selectedAgendaDay.querySelectorAll(
    ".el-event-agenda-session"
  );

  if (agendaSessions0.length > 0) {
    const singleSessions =
      agendaSessions0[0].querySelectorAll(".single-session");

    if (singleSessions.length > 0) {
      singleSessions[0].classList.remove("-hidden");
    }
  }
}

if (document.getElementById("el-event-agenda")) {
  document.addEventListener("DOMContentLoaded", function () {
    // Div and buttons selection
    let dayButtons = document.querySelectorAll(".el-event-daily-nav button");
    let agendaDays = document.querySelectorAll(".el-event-agenda-day");
    /**
     * INIT CONTENT ON PAGE LOAD
     */
    init(1, agendaDays);

    /**
     *
     * 01. DAY SELECTION
     *
     */

    // Button eventlistener
    dayButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        // Get data-day-target value
        let selectedDay = button.dataset.dayTarget;

        // Remove '-active' class from all DAY buttons
        dayButtons.forEach(function (dayButton) {
          dayButton.classList.remove("-active");
        });

        // Hide all DAY divs
        agendaDays.forEach(function (agendaDay) {
          agendaDay.classList.add("-hidden");
        });

        // show targeted DAY div
        let selectedAgendaDay = document.querySelector(
          '.el-event-agenda-day[data-day="' + selectedDay + '"]'
        );
        if (selectedAgendaDay) {
          //BUTTONS
          // Add .-active on clicked day button
          this.classList.add("-active");

          /**
           * SESSION NAV
           */
          //Remove .-active on all session buttons
          selectedAgendaDay
            .querySelectorAll(".session")
            .forEach(function (sessionBtn) {
              sessionBtn.classList.remove("-active");
            });

          // Add .-active on first session button of the list
          selectedAgendaDay
            .querySelectorAll(".session")[0]
            .classList.add("-active");

          /**
           * STAGE NAV
           */
          //Hide all stage nav in the page
          selectedAgendaDay
            .querySelectorAll(".el-event-agenda-session-nav")
            .forEach(function (sessionBtn) {
              sessionBtn.classList.add("-hidden");
            });

          //Show first stage nav (aka linked to first session button)
          selectedAgendaDay
            .querySelectorAll(".el-event-agenda-session-nav")[0]
            .classList.remove("-hidden");

          // Remove active class to all current day stage nav buttons
          selectedAgendaDay
            .querySelectorAll(".sub-session")
            .forEach(function (sessionBtn) {
              sessionBtn.classList.remove("-active");
            });

          //Add active state to the first button of the first stage nav
          selectedAgendaDay
            .querySelectorAll(".sub-session")[0]
            .classList.add("-active");

          /**
           * CONTENT
           */
          //Show parent container of selected day
          selectedAgendaDay.classList.remove("-hidden");

          // ARTICLES
          // Hide all articles (includes the stages)
          selectedAgendaDay
            .querySelectorAll(".el-event-agenda-session")
            .forEach(function (sessionDiv) {
              sessionDiv.classList.add("-hidden");
            });

          // Show first session article in the list
          selectedAgendaDay
            .querySelectorAll(".el-event-agenda-session")[0]
            .classList.remove("-hidden");
        }

        // Show all stage div of the first session article
        const agendaSessions0 = selectedAgendaDay.querySelectorAll(
          ".el-event-agenda-session"
        );

        if (agendaSessions0.length > 0) {
          const singleSessions =
            agendaSessions0[0].querySelectorAll(".single-session");

          if (singleSessions.length > 0) {
            singleSessions[0].classList.remove("-hidden");
          }
        }

        // Set agenda MAX-HEIGHT (on day button click)
        setHeight(agendaDays);
      });
    });

    // Set height on page load
    setHeight(agendaDays);

    // Set height on window resize
    window.addEventListener("resize", function () {
      setHeight(agendaDays);
    });

    /**
     *
     * 02. SESSION SELECTION
     *
     */
    // Div and buttons selection
    let sessionButtons = document.querySelectorAll(
      ".el-event-agenda-main-nav button"
    );
    let agendaSessions = document.querySelectorAll(".el-event-agenda-session");
    let stageNavItems = document.querySelectorAll(
      ".el-event-agenda-session-nav"
    );

    // Button eventlistener
    sessionButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        // Get data-session-target value
        let selectedSession = button.dataset.sessionTarget;

        /**
         * 01. Show / hide content window
         */
        // Remove '-active' class from all buttons
        sessionButtons.forEach(function (sessionButton) {
          sessionButton.classList.remove("-active");
        });

        // Hide all divs
        agendaSessions.forEach(function (agendaSession) {
          agendaSession.classList.add("-hidden");
        });

        // show targeted div
        let selectedAgendaSession = document.querySelector(
          '.el-event-agenda-session[data-session="' + selectedSession + '"]'
        );
        if (selectedAgendaSession) {
          //Show current session div
          selectedAgendaSession.classList.remove("-hidden");

          // Add active state to current session button
          this.classList.add("-active");

          // remove active state to all stage nav buttons
          let stageButtons = document.querySelectorAll(".sub-session");
          stageButtons.forEach(function (stageButton) {
            stageButton.classList.remove("-active");
          });

          // Add active state to first stage nav button
          let selectedStageNav = document.querySelector(
            '.el-event-agenda-session-nav[data-session-nav="' +
              selectedSession +
              '"]'
          );
          selectedStageNav
            .getElementsByTagName("button")[0]
            .classList.add("-active");

          // Show all stage div of the current session article
          const singleSessions =
            selectedAgendaSession.querySelectorAll(".single-session");

          if (singleSessions.length > 0) {
            singleSessions.forEach(function (singleSession) {
              singleSession.classList.remove("-hidden");
            });
          }
        }

        /**
         * 02. Show / hide subnavigation
         */

        // Loop through each element with the class ".el-event-agenda-session-nav"
        stageNavItems.forEach((item) => {
          // Add the "-hidden" class to hide the element
          item.classList.add("-hidden");
        });

        let stageNav = document.querySelector(
          '.el-event-agenda-session-nav[data-session-nav="' +
            selectedSession +
            '"]'
        );

        if (stageNav) {
          stageNav.classList.remove("-hidden"); // Show the specific element
        }
      });
    });

    // Set agenda MAX-HEIGHT (on page load) and Display end-time
    agendaDays.forEach((day) => {
      /**
       * 01. set agenda max-height
       */
      let asideElement = day.querySelector(".el-event-agenda-main-nav-list");
      let container = day.querySelector(".el-event-agenda-content");
      console.log("aside height on page load: " + asideElement.clientHeight);

      // get height value + 20px (prevent border-radius issue) DEPRECATED
      //let asideHeight = asideElement.clientHeight + 20;
      let asideHeight = asideElement.clientHeight;

      // If aside height is > than css defined min-height, override it to prevent border-radius issue on parent div
      if (asideHeight > 500) {
        day.style.minHeight = `${asideHeight}px`;
        container.style.minHeight = `${asideHeight}px`;
      }

      // set max-height style value
      day.style.maxHeight = `${asideHeight}px`;
      container.style.maxHeight = `${asideHeight}px`;

      // Select all buttons of a specific day
      let sessionButtons = day.querySelectorAll(
        ".el-event-agenda-main-nav button"
      );
    });

    /**
     *
     *
     * 03. SHOW / HIDE CURRENT SELECTED SESSION STAGES WINDOWS
     *
     */
    let stageItems = document.querySelectorAll(".single-session");
    let stageButtons = document.querySelectorAll(".sub-session");
    let allStageButtons = document.querySelectorAll(".sub-session-all");

    // Navigate between selected stages
    stageButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        // Get data-session-target value
        let selectedStage = button.dataset.subsessionTarget;
        console.log("selectedStage : " + selectedStage);

        // Hide all divs
        stageItems.forEach(function (stageItem) {
          stageItem.classList.add("-hidden");
        });

        // remove active class on buttons
        stageButtons.forEach(function (stageButton) {
          stageButton.classList.remove("-active");
        });

        allStageButtons.forEach(function (stageButton) {
          stageButton.classList.remove("-active");
        });

        // show targeted div
        let selectedStageItem = document.querySelector(
          '.single-session[data-sub-session="' + selectedStage + '"]'
        );
        if (selectedStageItem) {
          selectedStageItem.classList.remove("-hidden");
          this.classList.add("-active");
        }
      });
    });

    //Show all stages items
    allStageButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        // remove active class on buttons
        stageButtons.forEach(function (stageButton) {
          stageButton.classList.remove("-active");
        });

        // remove hidden class on items
        stageItems.forEach(function (stageItem) {
          stageItem.classList.remove("-hidden");
        });

        this.classList.add("-active");
      });
    });
  });
}
