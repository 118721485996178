import "slick-carousel";
(function ($) {
  $(function () {
    // hero carousel
    $("#hero-carousel .-carousel").slick({
      rows: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      //autoplay: false,
      //autoplaySpeed: 6000,
    });
    $("#hero-carousel-small .-carousel").slick({
      rows: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      //autoplay: false,
      //autoplaySpeed: 6000,
    });
    //stats slider
    $(".stats-slider").slick({
      rows: 0,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      //autoplay: true,
      //autoplaySpeed: 2000,
      centerPadding: "120px",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    //testimnoials slider (elementor test)
    $(".el_testimonial-js").slick({
      rows: 0,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      //autoplay: true,
      //autoplaySpeed: 2000,
      centerPadding: "120px",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $(".dual-section .image-slider").slick({
      rows: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      fade: true,
      //autoplay: true,
      //autoplaySpeed: 3000,
    });
    $(".-solutions .-list").slick({
      rows: 0,
      dots: true,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    });
    $(".-events-js .-list").slick({
      rows: 0,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: false,
      //appendDots: "dotted-nav",
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    });
    $(".el-featured-talks .-list").slick({
      rows: 0,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: false,
      //appendDots: "dotted-nav",
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    });
    $(".testimonial-carousel").slick({
      rows: 0,
      dots: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      adaptiveHeight: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    });
    $(".el-speakers__slick-card").slick({
      rows: 0,
      // dots: true,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $(".el-sponsors .tier .-slick").slick({
      rows: 0,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });
    $(".tv-list").slick({
      rows: 0,
      dots: true,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: false,
      appendDots: $(".pages"),
      prevArrow: $(".-prev"),
      nextArrow: $(".-next"),
      responsive: [
        {
          breakpoint: 2560,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
          },
        },
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $("#hero-posts-preview .post-carousel").slick({
      rows: 0,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      infinite: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      prevArrow: $(".-prev.-hero-posts"),
      nextArrow: $(".-next.-hero-posts"),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    });
    $(".component-posts-preview .post-carousel").slick({
      rows: 0,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      infinite: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      adaptiveHeight: true,
      prevArrow: $(".-prev.-posts"),
      nextArrow: $(".-next.-posts"),
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            swipeToSlide: false,
            // vertical: false,
            // verticalSwiping: false,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            swipeToSlide: false,
            verticalSwiping: false,
          },
        },
      ],
    });
    $(".-upcoming-webinar .-list").slick({
      rows: 0,
      dots: true,
      arrows: false,
      centerMode: true,
      centerPadding: "270px",
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            centerMode: true,
            centerPadding: "270px",
          },
        },
        {
          breakpoint: 1440,
          settings: {
            centerMode: true,
            centerPadding: "48px",
          },
        },
        {
          breakpoint: 992,
          settings: {
            centerMode: true,
            centerPadding: "16px",
          },
        },
      ],
    });
    $(".-advisory-board .-list.desktop").slick({
      rows: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      //autoplay: true,
      autoplaySpeed: 3000,
    });
    $(".-advisory-board .-list.mobile").slick({
      rows: 0,
      slidesToShow: 2,
      slidesToScroll: 2,
      dots: false,
      arrows: true,
      //autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $(".el-related_content .-list").slick({
      rows: 0,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    // const options_team = {
    //   rows: 0,
    //   mobileFirst: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   dots: true,
    //   arrows: false,
    //   autoplay: true,
    //   autoplaySpeed: 2000,
    //   responsive: [
    //     {
    //       breakpoint: 576,
    //       settings: "unslick",
    //     },
    //   ],
    // };

    // var slicky = $(".-team .-list");
    // slicky.slick(options_team);

    // $(window).resize(function () {
    //   setTimeout(function () {
    //     if (
    //       $(window).width() < 576 &&
    //       !slicky.hasClass("slick-initialized")
    //     ) {
    //       slicky.slick(options_team);
    //     }
    //   }, 100);
    // });

    // $(".-speakers .-list.desktop").slick({
    //   rows: 0,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   dots: true,
    //   arrows: false,
    //   autoplay: true,
    //   autoplaySpeed: 3000,
    // });

    // const options_speakers = {
    //   rows: 0,
    //   mobileFirst: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   dots: true,
    //   arrows: false,
    //   autoplay: true,
    //   autoplaySpeed: 2000,
    //   responsive: [
    //     {
    //       breakpoint: 992,
    //       settings: "unslick",
    //     },
    //     {
    //       breakpoint: 768,
    //       slidesToShow: 2,
    //     },
    //   ],
    // };

    // var slicky = $(".-speakers .-list.mobileonly");
    // slicky.slick(options_speakers);

    // $(window).resize(function () {
    //   setTimeout(function () {
    //     if (
    //       $(window).width() < 992 &&
    //       !slicky.hasClass("slick-initialized")
    //     ) {
    //       slicky.slick(options_speakers);
    //     }
    //   }, 100);
    // });
    // const options_advisors = {
    //   rows: 0,
    //   mobileFirst: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   dots: true,
    //   arrows: false,
    //   autoplay: true,
    //   autoplaySpeed: 2000,
    //   responsive: [
    //     {
    //       breakpoint: 992,
    //       settings: "unslick",
    //     },
    //     {
    //       breakpoint: 768,
    //       slidesToShow: 2,
    //     },
    //   ],
    // };

    // var slicky = $(".-advisory-board .-list.mobileonly");
    // slicky.slick(options_advisors);

    // $(window).resize(function () {
    //   setTimeout(function () {
    //     if ($(window).width() < 992 && !slicky.hasClass("slick-initialized")) {
    //       slicky.slick(options_advisors);
    //     }
    //   }, 100);
    // });
  });
})(jQuery);
