if (document.getElementById("el-event-agenda-mobile")) {
  document.addEventListener("DOMContentLoaded", function () {
    /**
     *
     * DAY SELECTION
     *
     */

    // Div and buttons selection
    let dayButtons = document.querySelectorAll(
      ".el-event-daily-nav-mobile button"
    );
    let agendaDays = document.querySelectorAll(".el-event-agenda-day-mobile");

    // Button eventlistener
    dayButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        // Get data-day-target value
        let selectedDay = button.dataset.dayTarget;

        // Remove '-active' class from all DAY buttons
        dayButtons.forEach(function (dayButton) {
          dayButton.classList.remove("-active");
        });

        // Hide all DAY divs
        agendaDays.forEach(function (agendaDay) {
          agendaDay.classList.add("-hidden");
        });

        // show targeted DAY div
        let selectedAgendaDay = document.querySelector(
          '.el-event-agenda-day-mobile[data-day="' + selectedDay + '"]'
        );
        if (selectedAgendaDay) {
          // Add .-active on clicked day button
          this.classList.add("-active");
          selectedAgendaDay.classList.remove("-hidden");
        }
      });
    });
  });
}
